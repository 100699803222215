import { ethers } from 'ethers';

import FAIRLAUNCH_CONTRACT_ABI from '../assets/abi/FAIRLAUNCH_CONTRACT_ABI.json';
import TOKEN_ABI from '../assets/abi/TOKEN_ABI.json';
import { Chain } from '../types/IAddresses';

export const NETWORK_ID = 1;
export const INFURA_ID = "7b1a9780e93148b282c6ebdae38ce20f";
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000"

export const chainInfo: Chain = {
    1: {
        CHAIN:                       "mainnet",
        CHAIN_HEX:                   "0x1",
        CHAIN_NAME:                  "Ethereum Mainnet",
        ETHERSCAN_API:               "https://api.etherscan.io/",
        BLOCK_EXPLORER:              "https://etherscan.io",
        PROVIDER:                    `https://eth.llamarpc.com`,
        TOKEN: {
            ADDRESS:                 "0x9a0df129e798438a8ad995368bd82baa7eee8913",
            ABI:                     TOKEN_ABI as ethers.ContractInterface
        },
        FAIRLAUNCH: {
            ADDRESS:                 "0x04e39c67b2047c2a8b573fbd691e30b2496cabe3",
            ABI:                     FAIRLAUNCH_CONTRACT_ABI as ethers.ContractInterface
        },
    },
    31337: {
        CHAIN:                       "hardhat",
        CHAIN_NAME:                  "Hardhat Node",
        CHAIN_HEX:                   "0x7a69",
        ETHERSCAN_API:               "",
        BLOCK_EXPLORER:              "",
        PROVIDER:                    `http://127.0.0.1:8545/`,
        TOKEN: {
            ADDRESS:                 "0xB581C9264f59BF0289fA76D61B2D0746dCE3C30D",
            ABI:                     TOKEN_ABI as ethers.ContractInterface
        },
        FAIRLAUNCH: {
            ADDRESS:                 "0x73511669fd4dE447feD18BB79bAFeAC93aB7F31f",
            ABI:                     FAIRLAUNCH_CONTRACT_ABI as ethers.ContractInterface
        },
    }
}


export const BIG_DECIMAL_18 = 1E18;

export const chain = chainInfo[NETWORK_ID];