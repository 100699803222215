import { useEffect, useState } from 'react';

import { fairLaunchContract } from '../utils/contracts';
import { useWeb3Context } from './useWeb3Context';
import { onChainProvider } from '../types/OnChainProvider';

export const useTokenAddress = (refresh: boolean = false) => {
    const { provider }: onChainProvider = useWeb3Context();
    const [tokenAddress, setTokenAddress] = useState<string>("")
    const [tokenAddressLoading, setTokenAddressLoading] = useState<boolean>(true)

    useEffect(() => {
        const fetch = async () => {
            try {
                const e = await fairLaunchContract(provider).token();
                setTokenAddress(e);
                setTokenAddressLoading(false);
            } catch {
                console.log('ERROR useTokenAddress')
            }
        }

        fetch();

    }, [refresh])

    return {
        tokenAddress,
        tokenAddressLoading
    }
};