import React, { useState } from 'react';

import { truncateFromCenter } from '../../helpers/sharedFunctions';
import { useWeb3Context } from '../../hooks/useWeb3Context';
import { onChainProvider } from '../../types/OnChainProvider';

interface Props {
    disabled?: boolean;
    className?: string;
    icon?: JSX.Element;
    text?: string
}

const ConnectButton: React.FC<Props> = (props) => {
    const { text, disabled, className, icon }: Props = props;
    const { address, connect, connected, hasToSwitchNetwork, switchNetwork, switchingNetworkIsLoading }: onChainProvider = useWeb3Context();
    const [openWalletInfo, setOpenWalletInfo] = useState(false);

    return (
        <React.Fragment>
            <button
                disabled={disabled || switchingNetworkIsLoading}
                className={`${className ? className : 'button btn-main'}`}
                onClick={() => hasToSwitchNetwork ? switchNetwork() : !connected ? connect() : setOpenWalletInfo(!openWalletInfo)}>
                {icon ? icon : null}
                {
                    switchingNetworkIsLoading
                        ? 'Switching network...'
                        : hasToSwitchNetwork
                            ? 'Switch Network' :
                            !connected
                                ? text ? text : 'Connect Wallet'
                                : truncateFromCenter(address)
                }
            </button>
        </React.Fragment>
    )
}

export default ConnectButton;