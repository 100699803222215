import { useEffect, useState } from 'react';

import { fairLaunchContract } from '../utils/contracts';
import { useWeb3Context } from './useWeb3Context';
import { onChainProvider } from '../types/OnChainProvider';
import BigNumber from 'bignumber.js';

export const useAvailableTokensICO = (refresh: boolean = false) => {
    const { provider }: onChainProvider = useWeb3Context();
    const [availableTokensICO, setAvailableTokensICO] = useState<BigNumber>(new BigNumber(""))
    const [availableTokensICOLoading, setAvailableTokensICOLoading] = useState<boolean>(true)

    useEffect(() => {
        const fetch = async () => {
            try {
                // Fetch available tokens to sold
                const availableTokensICO = await fairLaunchContract(provider).availableTokensICO();
                const availableTokensICOFormatted = new BigNumber(availableTokensICO.toString()).isGreaterThan(0) ? new BigNumber(availableTokensICO.toString()).div(1e18) : new BigNumber(0)
                setAvailableTokensICO(availableTokensICOFormatted);
                setAvailableTokensICOLoading(false);
            } catch {
                console.log('ERROR useAvailableTokensICO')
            }
        }

        fetch();

    }, [refresh])

    return {
        availableTokensICO,
        availableTokensICOLoading
    }
};