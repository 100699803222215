import { useEffect, useState, useCallback, useMemo } from "react";
import { Zoom, Grid, Typography, Button } from "@material-ui/core";
import { catchErrorMessage, showToast } from "../../helpers/sharedFunctions";

import ethIcon from '../../assets/images/ethereum-icon-light.svg';
import beepIcon from '../../assets/images/token_beep.jpg';
import BigNumber from "bignumber.js";
import ConnectButton from "../../components/ConnectButton/ConnectButton";
import { onChainProvider } from "../../types/OnChainProvider";
import { useWeb3Context } from "../../hooks/useWeb3Context";
import { useBalance } from "../../hooks/useBalance";
import { useEndIco } from "../../hooks/useEndIco";
import { fairLaunchContract } from "../../utils/contracts";
import { useAvailableTokensICO } from "../../hooks/useAvailableTokens";
import { useTokenAddress } from "../../hooks/useTokenAddress";
import { useMaxPurchase } from "../../hooks/useMaxPurchase";
import { useMinPurchase } from "../../hooks/useMinPurchase";
import { useRate } from "../../hooks/useRate";
import { IJsonRPCError } from "../../types/IJsonRPCError";
import { useInvestorStats } from "../../hooks/useInvestorStats";
import { BIG_DECIMAL_18, ZERO_ADDRESS } from "../../utils/constants";
import { usePaused } from "../../hooks/usePaused";
import { PresaleStatus } from "../../types/PresaleStatus";
import { useWhitelist } from "../../hooks/useWhitelist";

function Presale() {
    const [refreshAllHooks, setRefreshAllHooks]: any = useState(false);

    const { provider, address }: onChainProvider = useWeb3Context();
    const signer = provider.getSigner();

    const { isInvestorWhitelisted, investorSignature, whitelistActive, whitelistActiveLoading } = useWhitelist(refreshAllHooks);
    const { buyAndWithdrawIsLoading, buyActive, withdrawActive } = usePaused(refreshAllHooks);
    const { ETHBalanceIsLoading, ETHBalance } = useBalance(refreshAllHooks);
    const { icoHasEndedLoading, icoHasEnded } = useEndIco(refreshAllHooks);
    const { availableTokensICOLoading, availableTokensICO } = useAvailableTokensICO(refreshAllHooks);
    const { tokenAddressLoading, tokenAddress } = useTokenAddress(refreshAllHooks)
    const { maximumETHToSpendLoading, maximumETHToSpend } = useMaxPurchase(refreshAllHooks)
    const { minimumETHToSpendLoading, minimumETHToSpend } = useMinPurchase(refreshAllHooks)
    const { rateLoading, ratePerTokenInWei } = useRate(refreshAllHooks)
    const { investorLoading, investor, investorETHSpendLeft, investorETHSpend } = useInvestorStats(refreshAllHooks)

    const [txIsLoadingClaim, setTxIsLoadingClaim]: any = useState(false);
    const [txIsLoadingBuy, setTxIsLoadingBuy]: any = useState(false);
    const [ETHInput, setETHInput]: any = useState(0);
    const [BEEPInput, setBEEPInput]: any = useState(0);

    const loadingInit = useMemo(() => (whitelistActiveLoading || buyAndWithdrawIsLoading || ETHBalanceIsLoading || availableTokensICOLoading || icoHasEndedLoading || tokenAddressLoading || maximumETHToSpendLoading || minimumETHToSpendLoading || rateLoading || investorLoading), [ETHBalanceIsLoading, availableTokensICOLoading, icoHasEndedLoading, tokenAddressLoading, maximumETHToSpendLoading, minimumETHToSpendLoading, rateLoading, investorLoading, buyAndWithdrawIsLoading, whitelistActiveLoading])

    const onBuy = async () => {
        if (new BigNumber(availableTokensICO).isEqualTo(0)) return showToast(`Pre-sale sold out`, true);
        if (handleErrorMessages().length > 0) return showToast(handleErrorMessages(), true);

        const onSuccess = async (func: any) => {
            const txLog = await func.wait(); // Tx has been confirmed
            if (txLog) {
                showToast(`Succesfully purchased tokens`, false);
                setTxIsLoadingBuy(false);
                setRefreshAllHooks(true);

                setTimeout(() => {
                    setRefreshAllHooks(false);
                }, 2000)
            }
        }

        const onError = (err: unknown) => {
            const rpcError = err as IJsonRPCError
            const message = catchErrorMessage(rpcError);
            showToast(message, true);
            setTxIsLoadingBuy(false);
        }

        try {
            setTxIsLoadingBuy(true);
            const options = { value: new BigNumber(ETHInput).times(BIG_DECIMAL_18).toString() }
            const buyTokens = await fairLaunchContract(signer).buyTokens(investorSignature, options)
            if (buyTokens.hash) await onSuccess(buyTokens);
        } catch (e: any) {
            onError(e);
        }
    }

    const onClaim = async () => {
        const withdrawalAmount = investor && new BigNumber(investor.amountToReceive.toString()).div(BIG_DECIMAL_18);
        if (new BigNumber(withdrawalAmount).isEqualTo(0)) return showToast(`You haven't purchased any tokens to withdraw from`, true); //
        if (tokenAddress === ZERO_ADDRESS) return showToast(`Token address is the zero address`, true);
        if (new BigNumber(availableTokensICO).isEqualTo(0)) return showToast(`Pre-sale sold out`, true);
        // if (handleErrorMessages().length > 0) return showToast(handleErrorMessages(), true);

        const onSuccess = async (func: any) => {
            const txLog = await func.wait(); // Tx has been confirmed
            if (txLog) {
                showToast(`Succesfully claimed tokens`, false);
                setTxIsLoadingClaim(false);
                setRefreshAllHooks(true);

                setTimeout(() => {
                    setRefreshAllHooks(false);
                }, 2000)
            }
        }

        const onError = (err: unknown) => {
            const rpcError = err as IJsonRPCError
            const message = catchErrorMessage(rpcError);
            showToast(message, true);
            setTxIsLoadingClaim(false);
        }

        try {
            setTxIsLoadingClaim(true);
            const withdrawTokens = await fairLaunchContract(signer).withdrawTokens()
            if (withdrawTokens.hash) await onSuccess(withdrawTokens);
        } catch (e: any) {
            onError(e);
        }
    }

    const onETHMax = () => {
        const max = new BigNumber(availableTokensICO).lte(investorETHSpendLeft) ? availableTokensICO : investorETHSpendLeft;
        onETHInput({ target: { value: new BigNumber(ETHBalance).gte(max) ? max : ETHBalance } })
    }

    const onETHInput = (e: any) => {
        if (new BigNumber(e.target.value).lt(0)) e.target.value = 0;

        if (e.target.value === '') {
            setETHInput(e.target.value);
            setBEEPInput(0)
        } else {
            setInputValuesBasedOnETHtoBEEP(e.target.value, false);
        }
    }

    const onBeepInput = (e: any) => {
        if (e.target.value === '') {
            setBEEPInput(e.target.value);
            setETHInput(0)
        } else {
            setInputValuesBasedOnETHtoBEEP(e.target.value, true);
        }
    }

    const setInputValuesBasedOnETHtoBEEP = useCallback((input: any, isBEEPInput: any) => {
        if (isBEEPInput) {
            setBEEPInput(input)
            const inputInWei = new BigNumber(input)
            const receiveETH = new BigNumber(inputInWei.toString()).div(ratePerTokenInWei.toString()).times(BIG_DECIMAL_18).toString();
            setETHInput(receiveETH);
        } else {
            setETHInput(input);
            const inputInWei = new BigNumber(input);
            const receiveBeep = new BigNumber(inputInWei.toString()).times(ratePerTokenInWei.toString()).div(BIG_DECIMAL_18).toString();
            setBEEPInput(receiveBeep)
        }
    }, [ratePerTokenInWei])

    const handleErrorMessages = () => {
        if (!loadingInit) {
            if (new BigNumber(ETHInput).isLessThan(minimumETHToSpend)) return `The minimum amount is ${numberWithCommas(minimumETHToSpend)} ETH`
            if (new BigNumber(ETHInput).isGreaterThan(ETHBalance) && new BigNumber(ETHBalance).isGreaterThan(0)) return `Exceeds ETH balance`
            if (new BigNumber(ETHInput.toString()).isGreaterThan(investorETHSpendLeft) && new BigNumber(investorETHSpendLeft).isGreaterThan(0)) return `Reached the max amount to spend. Amount available: ${investorETHSpendLeft}`
            if (new BigNumber(ETHInput).isGreaterThan(maximumETHToSpend)) return `The maximum amount is ${numberWithCommas(maximumETHToSpend)} ETH`
            if (new BigNumber(BEEPInput).isGreaterThan(availableTokensICO)) return `The receive amount is higher than the available token`
        }
        return '';
    }

    const numberWithCommas = (x: any) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    const renderButtons = () => {
        const renderPurchaseTxt = () => {
            if (loadingInit) return 'Loading...';
            if (!isInvestorWhitelisted && whitelistActive) return 'NOT ELIGBLE';
            if (new BigNumber(investorETHSpendLeft).isEqualTo(0)) return 'Reached max buy'
            if (new BigNumber(availableTokensICO).isEqualTo(0)) return 'Sold out';
            if (txIsLoadingBuy) return 'Pending...';
            if (!txIsLoadingBuy) return 'Purchase';
        }

        const renderClaimTxt = () => {
            if (loadingInit) return 'Loading...';
            if (new BigNumber(availableTokensICO).isEqualTo(0)) return 'Sold out';
            if (txIsLoadingClaim) return 'Pending...';
            if (!txIsLoadingClaim) return 'Claim';
        }

        if (!address) {
            return (<ConnectButton className="button" text={loadingInit ? 'Loading...' : 'Connect Wallet'} />)
        } else if (icoHasEnded) {
            return (
                <button
                    className="button"
                    disabled={
                        txIsLoadingClaim
                    }
                    onClick={async () => await onClaim()}>
                    {renderClaimTxt()}
                </button>
            )
        } else {
            return (
                <button
                    className="button"
                    disabled={
                        (handleErrorMessages().length > 0) ||
                        (txIsLoadingBuy) ||
                        (new BigNumber(availableTokensICO).isEqualTo(0)) ||
                        (new BigNumber(BEEPInput).isLessThan(minimumETHToSpend)) ||
                        new BigNumber(investorETHSpendLeft).isEqualTo(0) ||
                        (BEEPInput == 0) ||
                        loadingInit ||
                        !buyActive ||
                        (!isInvestorWhitelisted && whitelistActive)
                    }
                    onClick={async () => await onBuy()}>
                    {renderPurchaseTxt()}
                </button>
            )
        }
    }

    const getPresaleStatus = useMemo(() => {
        if (icoHasEnded) return PresaleStatus.CLOSED;
        if (!icoHasEnded && buyActive) return PresaleStatus.OPEN;
        if ((!buyActive && !buyAndWithdrawIsLoading) || (!withdrawActive && !buyAndWithdrawIsLoading)) return PresaleStatus.PAUSED;
        if (new BigNumber(availableTokensICO).isEqualTo(0)) return PresaleStatus.SOLD_OUT;
        return PresaleStatus.OPEN
    }, [availableTokensICO, buyActive, buyAndWithdrawIsLoading, icoHasEnded, withdrawActive]);

    useEffect(() => {
        setETHInput(minimumETHToSpend);
        const inputInWei = new BigNumber(minimumETHToSpend);
        const receiveBeep = new BigNumber(inputInWei.toString()).times(ratePerTokenInWei.toString()).div(BIG_DECIMAL_18).toString();
        setBEEPInput(receiveBeep)
        // if (investor) {
        //     console.log('investor', investor)
        //     console.log('ethSpend', investor.ethSpend.toString())
        //     console.log('amountToReceive', investor.amountToReceive.toString())
        //     console.log('ratePerTokenInWei', ratePerTokenInWei.toString())
        //     console.log('investorETHSpendLeft', investorETHSpendLeft.toString())
        //     console.log('investorETHSpend', investorETHSpend.toString())
        //     console.log('availableTokensICO', availableTokensICO.toString())
        //     console.log('icoHasEnded', icoHasEnded)
        // }
    }, [availableTokensICO, icoHasEnded, investor, investorETHSpend, investorETHSpendLeft, minimumETHToSpend, ratePerTokenInWei, setInputValuesBasedOnETHtoBEEP, investor])

    return (
        <div id="presale-view">
            <div className="page-wrapper-bg"></div>
            <Zoom in={true}>
                <div className="main-container">
                    <Grid container direction="column">
                        <Grid className="card-grid" item>
                            <div className="main-big-card">
                                <div className="main-title">
                                    <h1>
                                        CLAIM&nbsp;
                                        {!loadingInit && <span className='color-green'>- ( OPEN )</span>}
                                    </h1>
                                </div>
                                <Typography variant="body1" color="textSecondary">
                                    BEEP is fully powered, thank you for your presale activity
                                    <br />
                                    <br />
                                    You can now claim your $BEEP tokens, BEEP launch imminent!
                                </Typography>
                                <div className='main-big-card-content'>
                                    <div className='form-control'>
                                        <div className='form-icon'>
                                            <img src={beepIcon} alt="BEEP" />
                                            BEEP
                                        </div>
                                        <input disabled value={!address || investorLoading || investor == null ? 0 : investor != null ? new BigNumber(investor.amountToReceive.toString()).div(BIG_DECIMAL_18).toNumber() ?? 0 : 0} type="number" />
                                    </div>
                                </div>
                                <div className={`main-big-card-btm single-btn`}>
                                    {renderButtons()}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Zoom>
        </div>
    );
}

export default Presale;
