import { useEffect, useState } from 'react';

import { fairLaunchContract } from '../utils/contracts';
import { useWeb3Context } from './useWeb3Context';
import { onChainProvider } from '../types/OnChainProvider';
import whitelisters from '../assets/whitelist/whitelist.json';


export const useWhitelist = (refresh: boolean = false) => {
    const { address, provider }: onChainProvider = useWeb3Context();
    const [whitelistActive, setWhitelistActive] = useState<boolean>(false)
    const [isInvestorWhitelisted, setIsInvestorWhitelisted] = useState<boolean>(false)
    const [investorSignature, setInvestorSignature] = useState<string>('0x5a051c397ee819d254925b2b5fa3d741b790c3366d1b7b4d41e5df278f3e3eec410191f5d7b91374a4e23024ff329a451903dee3cfd574144212bbf903cb642a2A');
    const [whitelistActiveLoading, setWhitelistActiveLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetch = async () => {
            try {
                // Set the rate
                const wa = await fairLaunchContract(provider).whitelistActive();
                setWhitelistActive(wa)
                setWhitelistActiveLoading(false)

                if(wa) {
                    if(address) {
                        const w = whitelisters.find((w) => w.buyer.toLowerCase() === address.toLowerCase())
                        if(w) {
                            setIsInvestorWhitelisted(true)
                            setInvestorSignature(w.signature)
                        }
                    }
                } else {
                    
                }
            } catch {
                console.log('ERROR useWhitelist')
            }
        }

        fetch();

    }, [address, refresh])

    return {
        whitelistActive,
        whitelistActiveLoading,
        isInvestorWhitelisted,
        investorSignature
    }
};