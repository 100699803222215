import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';

import { useWeb3Context } from './useWeb3Context';
import { onChainProvider } from '../types/OnChainProvider';
import { useAddress } from './useAddress';

export const useBalance = (refresh: boolean = false) => {
    const { address } = useAddress();
    const { provider }: onChainProvider = useWeb3Context();
    const [ETHBalance, setETHBalance] = useState<number>(0);
    const [ETHBalanceIsLoading, setETHBalanceIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetch = async () => {
            try {
                if (address) {
                    const balance = await provider.getBalance(address);
                    const balanceInEth = ethers.utils.formatEther(balance);
                    const formattedBalance = new BigNumber(balanceInEth.toString()).toNumber()
                    setETHBalance(formattedBalance);
                    setETHBalanceIsLoading(false);
                } else {
                    setETHBalanceIsLoading(false);
                }
            } catch {
                console.log('CATCH: useBalance')
                setETHBalanceIsLoading(false);
            }
        }

        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, refresh])

    return {
        ETHBalance,
        ETHBalanceIsLoading
    }
};