import { useEffect, useState } from 'react';

import { fairLaunchContract } from '../utils/contracts';
import { useWeb3Context } from './useWeb3Context';
import { onChainProvider } from '../types/OnChainProvider';
import BigNumber from 'bignumber.js';

export const useMaxPurchase = (refresh: boolean = false) => {
    const { provider }: onChainProvider = useWeb3Context();
    const [maximumETHToSpend, setMaximumETHToSpend] = useState<BigNumber>(new BigNumber(""));
    const [maximumETHToSpendLoading, setMaximumETHToSpendLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetch = async () => {
            try {
                // Fetch the maximum Purchase
                const maxPurchaseEther = await fairLaunchContract(provider).maxPurchase();
                const maxPurchaseFormatted = new BigNumber(maxPurchaseEther.toString()).div(1e18)
                setMaximumETHToSpend(maxPurchaseFormatted);
                setMaximumETHToSpendLoading(false);
            } catch {
                console.log('ERROR useMaxPurchase')
            }
        }

        fetch();

    }, [refresh])

    return {
        maximumETHToSpend,
        maximumETHToSpendLoading
    }
};