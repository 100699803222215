/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import ConnectButton from '../ConnectButton/ConnectButton';
import logo from '../../assets/images/beep-logo-light.png';

interface Props { }

function Menu(props: Props) {
  return (
    <div id="navbar">
    <div className="container">
      <div className="navbar-header">
        <a className="logo-main" href="/">
          <span className="logo-main-light">
            <img src={logo} alt="Beep" />
          </span>
        </a>
        <div className="nav-wrap">
          <nav id="nav-right">
            <ul className="nav-social">
              <li>
                <a href="https://discord.gg/beepmemecoin" target="_blank" title="Beep Discord">
                  <i className="fab fa-discord"></i>
                </a>
              </li>
              <li>
                <a href="https://www.twitter.com/beepmemecoin" target="_blank"
                  title="Beep Twitter">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
            </ul>
            <span className="d-inline-block">
              <ConnectButton className='button' />
            </span>
          </nav>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Menu
