import { useEffect, useState } from 'react';

import { fairLaunchContract } from '../utils/contracts';
import { useWeb3Context } from './useWeb3Context';
import { onChainProvider } from '../types/OnChainProvider';
import BigNumber from 'bignumber.js';
import { useAvailableTokensICO } from './useAvailableTokens';

export const useEndIco = (refresh: boolean = false) => {
    const { provider }: onChainProvider = useWeb3Context();
    const [icoHasEnded, setIcoHasEnded] = useState<boolean>(false)
    const [icoHasEndedLoading, setIcoHasEndedLoading] = useState<boolean>(true)
    const { availableTokensICO } = useAvailableTokensICO(refresh);

    useEffect(() => {
        const fetch = async () => {
            try {
                const e = await fairLaunchContract(provider).endICO();
                const blockNumber = await provider.getBlockNumber();

                const hasEnded = new BigNumber(e.toString()).lt(new BigNumber(blockNumber.toString()))
                setIcoHasEnded(hasEnded);
                setIcoHasEndedLoading(false);
            } catch {
                console.log('ERROR useEndIco')
            }
        }

        fetch();

    }, [refresh, availableTokensICO])

    return {
        icoHasEnded,
        icoHasEndedLoading
    }
};