import { useEffect, useState } from 'react';

import { fairLaunchContract } from '../utils/contracts';
import { useWeb3Context } from './useWeb3Context';
import { onChainProvider } from '../types/OnChainProvider';
import { useMaxPurchase } from './useMaxPurchase';
import BigNumber from 'bignumber.js';
import { BIG_DECIMAL_18 } from '../utils/constants';

export const useInvestorStats = (refresh: boolean = false) => {
    const { address, provider }: onChainProvider = useWeb3Context();
    const { maximumETHToSpend } = useMaxPurchase(refresh)
    const [investor, setInvestor] = useState<any>(null);
    const [investorLoading, setInvestorLoading] = useState<boolean>(true);
    const [investorETHSpend, setInvestorETHSpend] = useState<BigNumber>(new BigNumber("0"));
    const [investorETHSpendLeft, setInvestorETHSpendLeft] = useState<BigNumber>(new BigNumber("0"));

    useEffect(() => {
        const fetch = async () => {
            try {
                if (address) {
                    // Fetch the minimum Purchase
                    const i = await fairLaunchContract(provider).investor(address);
                    setInvestor(i)
                    
                    const ethSpend = i ? new BigNumber(i.ethSpend.toString()).div(BIG_DECIMAL_18) : new BigNumber("0")
                    const ethSpendLeft = new BigNumber(maximumETHToSpend).minus(ethSpend);
                    console.log('amountToReceive', i.amountToReceive.toString())
                    console.log('i', i)
                    console.log('ethSpend', ethSpend.toString())
                    console.log('ethSpendLeft', ethSpendLeft.toString())
                    if (i) {
                        setInvestorETHSpend(ethSpend);
                        setInvestorETHSpendLeft(ethSpendLeft);
                        setInvestorLoading(false)
                    } else {
                        setInvestorLoading(false)
                    }
                } else {
                    setInvestorLoading(false)
                }
            } catch {
                console.log('ERROR useInvestorStats')
            }
        }

        fetch();

    }, [address, refresh])

    return {
        investor,
        investorLoading,
        investorETHSpend,
        investorETHSpendLeft
    }
};