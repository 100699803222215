import { useEffect, useState } from 'react';

import { fairLaunchContract } from '../utils/contracts';
import { useWeb3Context } from './useWeb3Context';
import { onChainProvider } from '../types/OnChainProvider';

export const useRate = (refresh: boolean = false) => {
    const { provider }: onChainProvider = useWeb3Context();
    const [ratePerTokenInWei, setRatePerTokenInWei] = useState<string>("")
    const [rateLoading, setRateLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetch = async () => {
            try {
                // Set the rate
                const rpr = await fairLaunchContract(provider).rate();
                setRatePerTokenInWei(rpr)
                setRateLoading(false)
            } catch {
                console.log('ERROR useRate')
            }
        }

        fetch();

    }, [refresh])

    return {
        ratePerTokenInWei,
        rateLoading
    }
};