import { JsonRpcProvider, StaticJsonRpcProvider, Web3Provider } from '@ethersproject/providers';
import WalletConnectProvider from '@walletconnect/web3-provider';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import Web3Modal from 'web3modal';

import { chain, chainInfo, NETWORK_ID } from '../utils/constants';
import { Web3ContextData } from '../types/Web3ContextData';

export const Web3Context = React.createContext<Web3ContextData>(null);

export const Web3ContextProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
  const [connected, setConnected] = useState(false);
  // NOTE (appleseed): if you are testing on rinkeby you need to set chainId === 5 as the default for non-connected wallet testing...
  // ... you also need to set getTestnetURI() as the default uri state below
  const [chainID] = useState(NETWORK_ID);
  const [address, setAddress] = useState("");
  const [hasToSwitchNetwork, setHasToSwitchNetwork] = useState(false);
  const [switchingNetworkIsLoading, setSwitchingNetworkIsLoading] = useState<boolean>(false);

  const [uri] = useState(chain.PROVIDER);

  const [provider, setProvider] = useState<JsonRpcProvider>(new StaticJsonRpcProvider(uri));

  let web3Modal: Web3Modal;
  if (typeof window !== 'undefined') {
    web3Modal = new Web3Modal({
      // network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            rpc: {
              1: chainInfo[1].PROVIDER,
              31337: chainInfo[31337].PROVIDER,
            },
          },
        }
      },
    });
  }

  /**
  * throws an error if networkID is not 1 (mainnet) or 31337 (localhost)
  */
  const _checkNetwork = useCallback((otherChainID: number): Boolean => {
    if (chainID !== otherChainID) {
      console.warn("You are switching networks");
      console.warn("otherChainID", otherChainID);
      setHasToSwitchNetwork(true);
      return false;
    }
    return true;
  }, [chainID])

  // NOTE (appleseed): none of these listeners are needed for Backend API Providers
  // ... so I changed these listeners so that they only apply to walletProviders, eliminating
  // ... polling to the backend providers for network changes
  const _initListeners = useCallback((rawProvider: any) => {
    if (!rawProvider.on) {
      return;
    }
    rawProvider.on("accountsChanged", async (accounts: string[]) => {
      setTimeout(() => window.location.reload(), 1);
    });

    rawProvider.on("chainChanged", async (chain: number) => {
      _checkNetwork(chain);
      setTimeout(() => window.location.reload(), 1);
    });

    rawProvider.on("network", (_newNetwork: any, oldNetwork: any) => {
      if (!oldNetwork) return;
      window.location.reload();
    });
  }, [_checkNetwork]);

  // connect - only runs for WalletProviders
  const connect = useCallback(async () => {
    try {
      // handling Ledger Live;
      const rawProvider = await web3Modal.connect();

      // new _initListeners implementation matches Web3Modal Docs
      // ... see here: https://github.com/Web3Modal/web3modal/blob/2ff929d0e99df5edf6bb9e88cff338ba6d8a3991/example/src/App.tsx#L185
      _initListeners(rawProvider);
      const connectedProvider = new Web3Provider(rawProvider, "any");
      const chainId = await connectedProvider.getNetwork().then((network: any) => network.chainId);
      const connectedAddress = await connectedProvider.getSigner().getAddress();
      const validNetwork = _checkNetwork(chainId);
      if (!validNetwork) {
        console.error("Wrong network, please switch to mainnet");
        return;
      }
      // Save everything after we've validated the right network.
      // Eventually we'll be fine without doing network validations.
      setAddress(connectedAddress);
      setProvider(connectedProvider);

      // Keep this at the bottom of the method, to ensure any repaints have the data we need
      setConnected(true);

      return connectedProvider;
    } catch (e) {
      console.log('e', e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_initListeners, _checkNetwork]);

  const disconnect = useCallback(async () => {
    console.log("disconnecting");
    web3Modal.clearCachedProvider();
    setConnected(false);
    window.location.reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const switchNetwork = useCallback(async () => {
    try {
      setSwitchingNetworkIsLoading(true)
      if (window.ethereum) {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: chain.CHAIN_HEX }], // chainId must be in hexadecimal numbers
        });
        window.location.reload();
      }
    } catch (e) {
      setSwitchingNetworkIsLoading(false)
      console.log('switchNetwork', e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    const hasCachedProvider = (): Boolean => {
      if (!web3Modal) return false;
      if (!web3Modal.cachedProvider) return false;
      return true;
    }

    if (hasCachedProvider()) {
      connect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connect])

  const onChainProvider = useMemo(
    () => ({ connect, disconnect, provider, connected, address, chainID, web3Modal, uri, hasToSwitchNetwork, switchNetwork, switchingNetworkIsLoading }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [connect, disconnect, provider, connected, address, chainID, uri, hasToSwitchNetwork, switchNetwork, switchingNetworkIsLoading],
  );

  return <Web3Context.Provider value={{ onChainProvider }}>{children}</Web3Context.Provider>;
};