import { StaticJsonRpcProvider } from "@ethersproject/providers";
import { ethers } from "ethers";
import { chain } from "./constants";

export const tokenContract = (provider: StaticJsonRpcProvider | ethers.providers.JsonRpcSigner) => {
    return new ethers.Contract(chain.TOKEN.ADDRESS as string, chain.TOKEN.ABI, provider);
}

export const fairLaunchContract = (provider: StaticJsonRpcProvider | ethers.providers.JsonRpcSigner) => {
    return new ethers.Contract(chain.FAIRLAUNCH.ADDRESS as string, chain.FAIRLAUNCH.ABI, provider);
}