import { useEffect, useState } from 'react';

import { fairLaunchContract } from '../utils/contracts';
import { useWeb3Context } from './useWeb3Context';
import { onChainProvider } from '../types/OnChainProvider';

export const usePaused = (refresh: boolean = false) => {
    const { provider }: onChainProvider = useWeb3Context();
    const [buyActive, setBuyActive] = useState<boolean>(false);
    const [withdrawActive, setWithdrawActive] = useState<boolean>(false);
    const [buyAndWithdrawIsLoading, setBuyAndWithdrawIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetch = async () => {
            try {
                const ba = await fairLaunchContract(provider).buyActive();
                const wa = await fairLaunchContract(provider).withdrawActive();
                setBuyActive(ba);
                setWithdrawActive(wa);
                setBuyAndWithdrawIsLoading(false)
            } catch {
                console.log('ERROR usePaused')
            }
        }

        fetch();

    }, [refresh])

    return {
        buyAndWithdrawIsLoading,
        buyActive,
        withdrawActive
    }
};