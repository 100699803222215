import { useEffect, useState } from 'react';

import { fairLaunchContract } from '../utils/contracts';
import { useWeb3Context } from './useWeb3Context';
import { onChainProvider } from '../types/OnChainProvider';
import BigNumber from 'bignumber.js';

export const useMinPurchase = (refresh: boolean = false) => {
    const { provider }: onChainProvider = useWeb3Context();
    const [minimumETHToSpend, setMinimumETHToSpend] = useState<BigNumber>(new BigNumber(""));
    const [minimumETHToSpendLoading, setMinimumETHToSpendLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetch = async () => {
            try {
                // Fetch the minimum Purchase
                const minPurchaseEther = await fairLaunchContract(provider).minPurchase();
                const minPurchaseFormatted = new BigNumber(minPurchaseEther.toString()).div(1e18)
                setMinimumETHToSpend(minPurchaseFormatted);
                setMinimumETHToSpendLoading(false);
            } catch {
                console.log('ERROR useMinPurchase')
            }
        }

        fetch();

    }, [refresh])

    return {
        minimumETHToSpend,
        minimumETHToSpendLoading
    }
};